import { Loading } from "Components/Loading/Loading";
import * as React from "react";
import { Route, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useSharedQuery } from "services";

export const ProtectedRoute: React.FunctionComponent<{
    component: React.FunctionComponent;
    [x: string]: any;
}> = ({ component: Component, ...rest }) => {
    const { data, loading } = useSharedQuery();

    const redirectToLoginPage = async () => {
        window.location.href = `/login/`;
    }

    const redirectToHomePage = async () => {
        window.location.href = `/`;
    }

    React.useEffect(() => {
        if(data && !data.currentUser && window.location.pathname !== "/login/"){
            redirectToLoginPage()
        } else if (data && data.currentUser && !data.currentUser?.isAdmin && window.location.pathname !== "/login/") {
            toast.error("Account does not exist");
            redirectToLoginPage()
        } else if (data && data.currentUser && data.currentUser.isAdmin && window.location.pathname === "/login/") {
            console.log("redirect to homepage")
            redirectToHomePage()
        }
    }, [data]);
    return (
        <Route
            {...rest}
            render={(props) => {
                if (loading)
                    return (
                        <div className="text-center">
                            <Loading />
                        </div>
                    );
                else {
                    return !loading 
                        // && data &&
                        // data.currentUser &&
                        // data.currentUser?.isAdmin 
                        ? (
                        <Component />
                        // ) : (
                        //     <RedirectLogin />
                        // );
                    ) : null
                }
            }}
        />
    );
};
