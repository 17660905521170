import {
  Grid,
  Menu,
  Table,
  TableBody,
  TableCell,
  TableRow,
  MenuItem,
} from "@mui/material";
import PaginationControlled from "Components/PaginationControlled/PaginationControlled";
import * as React from "react";
import { CONSTANTS } from "consts";
import GLOBAL_FUNCTIONS from "scripts/apps/global-functions";
import EnhancedTableHead from "./CmsTableHead";
import ShortTextDisplay from "Components/ShortTextDisplay";
import style from "./CmsTable.module.scss";
import { ReactComponent as TrashIcon } from "./icons/trash.svg";
import { ReactComponent as EditIcon } from "./icons/edit.svg";
import { ReactComponent as DetailIcon } from "./icons/detail.svg";
import ApproveIcon from "./icons/approveIcon";
import RejectIcon from "./icons/rejectIcon";
import StatusLabel from "Components/StatusLabel";

interface CmsTableAdminProps {
  rowPerPage?: number;
  data?: any;
  notes?: any[];
  action?: any;
  actions?: any;
  handleClose?: (event: any, id: string) => void;
  handleDelete?: (event: any, n: any) => void;
  handleCloseEdit?: (event: any, n: any) => void;
  handleCopy?: (event: any, n: any) => void;
  handleViewDetail?: (event: any, n: any) => void;
  goToSub?: (event: any, n: number, field?: string) => void;
  handleChangePage?: (page: number) => void;
  handleClickRow?: (id: any) => void;
  headerToolBar?: any;
  loading?: boolean;
  isHidePagging?: boolean;
  isHideNumberCol?: boolean;
}
export const CmsTableAdmin = ({
  rowPerPage = 10,
  data,
  action,
  actions,
  notes,
  handleChangePage = () => {},
  goToSub = () => {},
  handleClose = () => {},
  handleDelete = () => {},
  handleCloseEdit = () => {},
  handleCopy = () => {},
  handleViewDetail = () => {},
  handleClickRow = () => {},
  headerToolBar,
  loading,
  isHidePagging,
  isHideNumberCol,
}: CmsTableAdminProps) => {
  const { columns, rows, totalCount } = data;
  const [state, setState] = React.useState({
    selected_category: ["All"],
    order: "asc",
    orderBy: "",
    selected: [],
    array_searched: [],
    progress_list: [],
    array_searched_bind: [],
    page: 0,
    rows_per_page: rowPerPage,
    list_name_column: null,
    disabled_next: false,
    disabled_prev: false,
    page_numbers: [],
  });

  React.useEffect(() => {
    setState(GLOBAL_FUNCTIONS.SetObject(state, { page: data.currentPage }));
  }, [data.currentPage]);
  // React.useEffect(() => {
  //     setState(
  //         GLOBAL_FUNCTIONS.SetObject(state, { rows_per_page: rowPerPage })
  //     );
  // }, [rowPerPage]);
  const handleRequestSort = (event: any, property: any) => {
    const orderBy = property;
    let order = "desc";

    if (state.orderBy === property && state.order === "desc") {
      order = "asc";
    }

    setState(GLOBAL_FUNCTIONS.SetObject(state, { order, orderBy }));
  };
  const Desc = (a: any, b: any, orderBy: string) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const GetSorting = (order: string, orderBy: string) => {
    return order === "desc"
      ? (a: any, b: any) => Desc(a, b, orderBy)
      : (a: any, b: any) => -Desc(a, b, orderBy);
  };

  const StableSort = (array: any[], cmp: Function) => {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  };

  const isSelectedFun = (id: any) => {
    return state.selected.indexOf(id as never) !== -1;
  };
  const handleClick = (event: any, id: any) => {
    const { selected } = state;
    const selectedIndex = selected.indexOf(id as never);
    let newSelected: any[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setState(GLOBAL_FUNCTIONS.SetObject(state, { selected: newSelected }));
    handleClickRow(id);
  };
  const totalPage = (rows_per_page: number) => {
    const total_rows = Math.ceil(totalCount / rows_per_page);
    const page_numbers = [];
    for (let i = 0; i < total_rows; i++) {
      page_numbers.push(i);
    }
    return page_numbers;
  };

  const handleChangeRowsPerPage = (event: any) => {
    const total_page = totalPage(event.target.value);
    setState(
      GLOBAL_FUNCTIONS.SetObject(state, {
        rows_per_page: event.target.value,
        page_numbers: total_page,
      })
    );
  };
  // React.useEffect(() => {
  //     const { rows_per_page } = state;
  //     const total_page = totalPage(rows_per_page);
  //     setState(
  //         GLOBAL_FUNCTIONS.SetObject(state, { page_numbers: total_page })
  //     );
  // }, [rows]);

  const [anchorEl, setAnchorEl] = React.useState<any>(null);

  return (
    <div className={"table " + style["cmsTableWrapper"]}>
      {headerToolBar && headerToolBar}
      <Table className="video-list-table custom-table-element">
        <EnhancedTableHead
          columns={columns}
          // numSelected={state.selected.length}
          order={state.order}
          orderBy={state.orderBy}
          // onSelectAllClick={handleSelectAllClick}
          // onRequestSort={handleRequestSort}
          // rowCount={rows.length}
          action={action || actions}
          isHideNumberCol={isHideNumberCol}
        />
        <TableBody>
          {StableSort(rows, GetSorting(state.order, state.orderBy))
            // .slice(state.page * state.rows_per_page, state.page * state.rows_per_page + state.rows_per_page)
            ?.map((n, k) => {
              const isSelected = isSelectedFun(n.id + `rowCmsTable${k}`);
              // console.log('lplpldlspldslds', n);
              const id = n.open ? "simple-popover" : undefined;
              return n.hide ? null : (
                <TableRow
                  hover
                  onClick={(event) =>
                    handleClick(event, n.id + `rowCmsTable${k}`)
                  }
                  role="checkbox"
                  aria-checked={isSelected}
                  tabIndex={-1}
                  key={n.id + `rowCMSTable${k}`}
                  selected={isSelected}
                  className="cms-table-row"
                >
                  {!isHideNumberCol && (
                    <TableCell>
                      {!loading
                        ? k + state.page * state.rows_per_page + 1
                        : "---"}
                    </TableCell>
                  )}
                  {columns.map((col: any, key: any) => {
                    return (
                      <TableCell
                        key={col.id + `${key}${k}` + "cellCMSTable"}
                        className={col?.className + " cellCMSTable"}
                      >
                        <span
                          role="presentation"
                          style={{
                            color: `${
                              col.checkStatus && notes
                                ? notes[n.status - 1].color
                                : ""
                            }`,
                          }}
                          onClick={
                            col.hasSub
                              ? (event) => goToSub(event, n, col.id)
                              : () => {}
                          }
                        >
                          {col.id === "status" ? (
                            <StatusLabel
                              text={(notes && notes[n.status - 1]?.value) || ""}
                              color={notes && notes[n.status - 1]?.color}
                              colorBg={notes && notes[n.status - 1]?.colorBg}
                            />
                          ) : (
                            <ShortTextDisplay
                              text={n[col.id]}
                              limit={col?.limitText || 30}
                            />
                          )}
                        </span>
                      </TableCell>
                    );
                  })}
                  {action && (
                    <TableCell>
                      <div className={style["tableActionGroup"] + " pointer"}>
                        {action.detail && (
                          <div
                            onClick={(event) => handleViewDetail(event, n)}
                            className={style["actionButton"]}
                          >
                            <DetailIcon />
                          </div>
                        )}
                        {action.edit && (
                          <div
                            onClick={(event: any) => handleCloseEdit(event, n)}
                            className={style["actionButton"]}
                          >
                            <EditIcon />
                          </div>
                        )}
                        {action.delete && (
                          <div
                            onClick={(event) => handleDelete(event, n)}
                            className={style["actionButton"]}
                          >
                            <TrashIcon />
                          </div>
                        )}
                      </div>
                    </TableCell>
                  )}
                  <>
                    {actions && (
                      <TableCell>
                        <div className={style["tableActionGroup"]}>
                          {actions.map((item: any) => {
                            return (
                              <>
                                {(item.name === "delete" && !n.hideDeleteRow) &&
                                  <div
                                    className={style["actionButton"]}
                                    onClick={(event: any) => item.handle(event, n)}
                                  >
                                    <TrashIcon />
                                  </div>
                                }
                                {(item.name === "edit" && !n.hideEditRow) &&
                                  <div
                                    className={style["actionButton"]}
                                    onClick={(event: any) => item.handle(event, n)}
                                  >
                                    <EditIcon />
                                  </div>
                                }
                                {(item.name === "detail" ||
                                  item.name === "View detail" ||
                                  item.name === "View Invoice") && !n.hideDetailRow &&
                                  <div
                                    className={style["actionButton"]}
                                    onClick={(event: any) => item.handle(event, n)}
                                  >
                                    <DetailIcon />
                                  </div>
                                }
                                {(item.name === "approve" && !n.hideApproveRow) &&
                                  <div
                                    className={style["actionButton"]}
                                    onClick={(event: any) => item.handle(event, n)}
                                  >
                                    <ApproveIcon
                                      className={style["approveIcon"]}
                                    />
                                  </div>
                                }
                                {(item.name === "reject" && !n.hideRejectRow) &&
                                  <div
                                    className={style["actionButton"]}
                                    onClick={(event: any) => item.handle(event, n)}
                                  >
                                    <RejectIcon
                                      className={style["approveIcon"]}
                                    />
                                  </div>
                                }
                              </>
                            );
                          })}
                        </div>
                      </TableCell>
                    )}
                  </>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      {!isHidePagging && (
        <div className={style["pagination-show"]}>
          <Grid
            container
            spacing={1}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid
              item
              xs={6}
              alignContent="flex-end"
              className={"numbersPagingWrapper"}
            >
              <PaginationControlled
                handleChangePage={handleChangePage}
                count={Math.ceil(data.totalCount / state.rows_per_page)}
                currentPage={data.currentPage}
              />
            </Grid>
            <Grid
              item
              xs={6}
              alignContent="flex-end"
              className={style["showingPagingDescItem"]}
            >
              <label
                htmlFor="cms-table-rows_per_page-id"
                className={style["text"]}
              >
                {/* Show&nbsp;&nbsp;
                                  <select
                                      id="cms-table-rows_per_page-id"
                                      className="row-per-page-select"
                                      onChange={handleChangeRowsPerPage}
                                      value={state.rows_per_page}
                                      name="entry"
                                  >
                                      {CONSTANTS.ROWS_PER_PAGE.map((t: number) => {
                                          return <option value={t}>{t}</option>;
                                      })}
                                  </select> */}
                Showing {rows.length || 0}
                &nbsp;&nbsp;entries of {totalCount} rows
              </label>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};
CmsTableAdmin.defaultProps = {
  data: {},
  notes: [],
  action: false,
  handleClose: () => {},
  handleDelete: () => {},
  handleCloseEdit: () => {},
  handleCopy: () => {},
  goToSub: () => {},
};
