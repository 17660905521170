import { MenuItem, Select } from "Components/base";
import style from "./GameTypeSelect.module.scss";
import React, { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { torem } from "styles/theme/toRem";
import { useAdminGameProductCategoryGroupsQuery, useAdminGameTypesQuery } from "services";

interface GameTypeSelectProps {
  onChange?: Function;
  value?: any;
  otherProps?: any;
  appId?: string | number;
}
const GameTypeSelect = ({
  onChange,
  value,
  otherProps,
  appId,
}: GameTypeSelectProps) => {
  // console.log('ppplll', appId)
  const {
    data,
    loading,
    refetch,
  } = useAdminGameTypesQuery({
    variables: {
      input: {
        appId: Number(appId),
      }
    },
  });

  // useEffect(()=>{
  //   if(appId) {
  //     refetch({
  //       input: {
  //         appId: appId
  //       }
  //     })
  //   }
  // },[appId])

  console.log('gameType', data)

  return (
    <div className={style["GameTypeSelect"] + " GameTypeSelect"}>
      <Select
        name="gameType"
        placeholder={"Select a Game type"}
        value={value}
        onChange={onChange}
        fullWidth
        {...otherProps}
      >
        {data && data?.adminGameTypes?.map((item: any, key: any) => {
          return (
            <MenuItem key={"gameTypeMenuItem" + key} value={item.id}>
              {item.name}
            </MenuItem>
          );
        })}
      </Select>
      {loading && (
        <div className={style["loading"]}>
          <CircularProgress size={torem(30)} />
        </div>
      )}
    </div>
  );
};

export default GameTypeSelect;
